<template>
  <router-view />
</template>

<script>
import platformModule from '../../store/platforms'

export default {
  beforeCreate () {
    this.$store.registerModule('platforms', platformModule)
  },
  destroyed () {
    this.$store.unregisterModule('platforms')
  }
}
</script>
