import {
  PLATFORM_FETCH_REQUEST,
  PLATFORM_FETCH_SUCCESS,
  PLATFORM_FETCH_FAILURE
} from './types'

export default {
  fetch ({ commit }, { from, to }) {
    commit(PLATFORM_FETCH_REQUEST)
    return window.axios.get(`/platform/charts`, { params: { from, to } }).then(response => {
      commit(PLATFORM_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(PLATFORM_FETCH_FAILURE, error)
      throw error
    })
  },
}
