import {
  PLATFORM_FETCH_REQUEST, PLATFORM_FETCH_SUCCESS, PLATFORM_FETCH_FAILURE
} from './types'

export default {
  [PLATFORM_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [PLATFORM_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [PLATFORM_FETCH_FAILURE] (state) {
    state.loading = false
  },
}
